/**********************************************************
/ NAME: DealSigned()
/********************************************************** 
 * SUMMARY: 
 * This component is rendered when a cargo match is signed. It displays a message indicating the necessary information
 * will be sent to the Cargo Owner from the Carrier.
 * 
/********************************************************** 
 * INPUT: 
 * It does not take any props. It uses the useLocation hook to get the state passed from the previous route.
 * 

/********************************************************** 
 * RETURN:
 * Returns a JSX element containing a message about the payment status.
/*********************************************************/
// Importing necessary libraries and components
import { Button, Text, VStack } from "@chakra-ui/react";
import OneColumnLayout from "../layout/OneColLayout";
import { useLocation } from "react-router-dom";
import { sendPackageInfoToOpter } from "../databasefunctions/matches";

interface CargoMatchState {
  tracking_number: string;
}


// PaymentStatus component
function DealSigned() {
  const { state } = useLocation();
  const { tracking_number } = state as CargoMatchState;

  const sendToOpter = () => {
    // Logic to send the offer to Opter
    sendPackageInfoToOpter(tracking_number);
  };
  
  // Render the component
  return (
    <OneColumnLayout
      sectionLabel="Dashboard"
      sectionHeading="Avtale gjort og oppdrag klar for utførelse"
      sectionIntro=""
      content={
        <VStack>
          <Text fontSize="2xl">Avtale om utførelse av oppdraget er klar</Text>
          <Text>
            Du kan velge å sende oppdraget inn til Opter med knappen under her.
          </Text>
          <Button variant="return" as="a" href="/dashboard">
            Tilbake
          </Button>

          <Button variant="outline" as="a" onClick={sendToOpter} style={{marginTop: "55px"}}>
            Send til Opter
          </Button>
        </VStack>
      }
    />
  );
}

// Exporting the component
export default DealSigned;
