/**********************************************************
/ NAME: CargoRow()
/********************************************************** 
 * SUMMARY: 
 * CargoRow is a component that displays the information about the cargo
 * 
 * 
/********************************************************** 
 * INPUT: 
 * cargo: The cargo object
 * 
/********************************************************** 
 * FUNCTIONS:
 * goToUpdateCargo(): Navigates to the update cargo page
 * findMatch(): Searches for a match for the cargo
 * evaluateMatch(): Goes to the page where the user can evaluate the match
 * onDeletePackage(): Deletes the cargo
 * 

/********************************************************** 
 * COMPONENTS:
 * 
/*********************************************************/

import { Box, Button, Grid, Divider, VStack, Center,Spinner } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { deleteCargo } from "../databasefunctions/cargos";
import { Alert } from "../layout/AlertDialog";
import { MdOutlineFindReplace } from "react-icons/md";
import { findCargoMatch } from "../databasefunctions/matches";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import DisplayIntegrationStatus from "../Matches/DisplayIntegrationStatus";
interface CargoRowProps {
  cargo: {
    tracking_number: string;
    name: string;
    pick_up_point: string;
    earliest_start_time: string;
    latest_start_time: string;
    earliest_end_time: string;
    latest_end_time: string;
    drop_of_point: string;
    vehicle_pk: string;
    package_height_cm: string;
    package_length_cm: string;
    package_width_cm: string;
    package_weight_kg: string;
    loads_types: [string];
    distance_m: string;
    duration_min: string;
    note: string;
    loading_requirements: [string];
    icoterm: {
      code: string;
      discription: string;
    };
    pending_matches: {
      car_reg_number: string;
      offer: string;
      status: string;
      note: string;
    };
  };
}
export default function CargoRow(props: CargoRowProps) {
  const {
    tracking_number,
    name,
    pick_up_point,
    earliest_start_time,
    latest_start_time,
    earliest_end_time,
    latest_end_time,
    icoterm,
    loads_types,
    drop_of_point,
    vehicle_pk,
    package_height_cm,
    package_length_cm,
    package_width_cm,
    package_weight_kg,
    
    distance_m,
    duration_min,
    loading_requirements,
    note,
  } = props.cargo;
  const [pending_matches, setPendingMatches] = useState<any>(props.cargo.pending_matches);
  const navigate = useNavigate();
  const [renderMe, setRenderMe] = useState<boolean>(true);
  const [searchMatch, setSearchMatch] = useState<boolean>(false);

  let sizestring: string;
  let UpdateButton = (
    <Button isDisabled variant="update" onClick={() => goToUpdateCargo()}>
      Endre
    </Button>
  );
  if (
    pending_matches["status"] === "Unmatched" ||
    pending_matches["status"] === "Declined"
  ) {
    UpdateButton = (
      <Button isActive variant="update" onClick={() => goToUpdateCargo()}>
        Endre
      </Button>
    );
  }
  function goToUpdateCargo() {
    navigate("/update-cargo", {
      state: {
        cargoName: name,
        cargoPickUpPoint: pick_up_point,
        cargoEarliestStartDate: earliest_start_time,
        cargoLatestStartDate: latest_start_time,
        cargoEarliestEndDate: earliest_end_time,
        cargoLatestEndDate: latest_end_time,
        cargoDropOfPoint: drop_of_point,
        cargoIncoTerms: icoterm,
        cargoLoadTypes: loads_types,
        cargoVehiclePk: vehicle_pk,
        cargoPackageHeightcm: package_height_cm,
        cargoPackageLengthcm: package_length_cm,
        cargoPackageWidthcm: package_width_cm,
        cargoPackageWeightkg: package_weight_kg,
        cargoTrackingNumber: tracking_number,
        cargoNote: note,
      },
    });
  }
  let buttonSize = "md";
  if (isMobile) {
    buttonSize = "sm";
  }
  let MatchButton = (
    <Center>
      <Button
        variant="primary"
        size={buttonSize}
        leftIcon={<MdOutlineFindReplace />}
        isActive
        onClick={() => findMatch()}
      >
        {searchMatch ? <Spinner size="sm" color="white" /> : 'Match'}
      </Button>
    </Center>
  );
  if (pending_matches["status"] === "Pending") {
    MatchButton = (
      <Center>
        <Button
          size={buttonSize}
          leftIcon={<MdOutlineFindReplace />}
          isDisabled
          onClick={() => findMatch()}
        >
          Match
        </Button>
      </Center>
    );
  } else if (pending_matches["status"] === "Offer Recieved") {
    MatchButton = (
      <Center>
        <Button
          variant="verify"
          size={buttonSize}
          onClick={() => EvaluateOffer()}
        >
          Evaluer
        </Button>
      </Center>
    );
  } else if (
    pending_matches["status"] === "Accepted" ||
    pending_matches["status"] === "Bid Accepted"
  ) {
    MatchButton = (
      <Center>
        <Button
          size={buttonSize}
          colorScheme={"green"}
          isDisabled
          onClick={() => EvaluateOffer()}
        >
          Akseptert
        </Button>
      </Center>
    );
  }


const findMatch = async () => {
  setSearchMatch(true);
  await findCargoMatch(tracking_number).then((res) => {
    console.log((res as any).data);
    setSearchMatch(false);
    setPendingMatches({ status: "Pending" })
  }).catch((err) => { console.log(err); setSearchMatch(false); })
};
      
  useEffect(() => {
    //console.log(searchMatch)
  }, [searchMatch, tracking_number,pending_matches["status"]]);

  const EvaluateOffer = async () => {
    navigate("/evaluate-offer-Cargo-Owner", {
      state: {
        distance_package: distance_m,
        duration_package: duration_min,
        package_height: package_height_cm,
        package_length: package_length_cm,
        package_width: package_width_cm,
        package_weight: package_weight_kg,
        pick_up_point: pick_up_point,
        drop_off_point: drop_of_point,
        earliest_start_time: earliest_start_time,
        latest_start_time: latest_start_time,
        earliest_end_time: earliest_end_time,
        latest_end_time: latest_end_time,
        load_types: loads_types,
        incoterms: icoterm,
        offer: pending_matches["offer"],
        tracking_number: tracking_number,
        car_reg_number: pending_matches["car_reg_number"],
        loading_requirements: loading_requirements,
        note: note,
        noteTrip: pending_matches["note"],
      },
    });
  };

  //console.log(pending_matches)
  async function onDeletePackage(tracking_number: string) {
    await deleteCargo(tracking_number);
    // deleteProject(projectID).then(() => {
    setRenderMe(false);
    // });
  }

  sizestring =
    package_height_cm + " x " + package_length_cm + " x " + package_width_cm;

  let dateString = earliest_start_time.split("T");
  //console.log(dateString[0])

  //console.log(pending_matches?.status)

  if (!renderMe) {
    return <></>;
  }
  return (
    <>
      <MobileView>
        <Grid mb={2} templateColumns="18vw 18vw 18vw 25vw" gap={2}>
          <VStack>
            <Box>{UpdateButton}</Box>
            <Box>
              <Alert
                onConfirm={() => onDeletePackage(tracking_number)}
                subject="Forsendelsen"
              />
            </Box>
          </VStack>
          <Center fontSize={"1.0rem"}>{pick_up_point}</Center>
          <Center fontSize={"1.0rem"}>{dateString[0]}</Center>

          {searchMatch && (
            <Center>
              <Button
                size={buttonSize}
                leftIcon={<MdOutlineFindReplace />}
                isDisabled
                onClick={() => findMatch()}
              >
                Søker
              </Button>
            </Center>
          )}
          {!searchMatch && MatchButton}
        </Grid>
        <Divider variant="thick" m="1" width={"85vw"} ml="-2"></Divider>
      </MobileView>
      <BrowserView>
        <Grid
          mb={2}
          templateColumns="6vw 10vw 10vw 10vw 10vw 10vw 10vw 10vw 8vw"
          gap={2}
        >
          <Center>
            <Alert
              onConfirm={() => onDeletePackage(tracking_number)}
              subject="Forsendelsen"
            />
          </Center>
          <Box fontSize={"1.0rem"}>{pick_up_point}</Box>
          <Box fontSize={"1.0rem"}>
            {dateString[0]} kl: {dateString[1]}
          </Box>
          <Box fontSize={"1.0rem"}>{drop_of_point}</Box>
          <Box fontSize={"1.0rem"}>{sizestring}</Box>
          <Box fontSize={"1.0rem"}>{package_weight_kg}</Box>
          
            <Box fontSize={"1.0rem"}>{pending_matches["status"]}
              {pending_matches["status"].indexOf("Accepted") === 0 ? (<div><DisplayIntegrationStatus tracking_number={tracking_number}></DisplayIntegrationStatus></div>) : ''}
            </Box>
          
          
          { MatchButton}
          <Center>{UpdateButton}</Center>

          <Divider variant="thick" mt="1" mb="1" width={"90vw"}></Divider>
        </Grid>
      </BrowserView>
    </>
  );
}
