import {
    SimpleGrid,
    AspectRatio,
    FormControl,
    Alert,
    AlertIcon,
    AlertDescription,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    FormLabel,
    ModalFooter,
  } from "@chakra-ui/react";
  import { Box, ButtonGroup, Input, Text, Button } from "@chakra-ui/react";
  import { Formik } from "formik";
  import { ResetButton, SubmitButton, TextareaControl } from "formik-chakra-ui";
import { getExtendedPackageInfoForIntegration, updateExtendedPackageInfoForIntegration } from "../databasefunctions/matches";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { PackageAddress, PackageInfoForIntegration, Role } from "../../types/packageIntegration";

interface CargoMatchState {
    tracking_number: string;
}



type RoleProps = {
  role: Role;
  updatePackageIntegrationInfo: (packageInfo: PackageInfoForIntegration) => void;
};


export default function ExtendedInfoIntegration(props: RoleProps) {

    const initialAddress: PackageAddress = {
        name: "",
        street_address: "",
        street_address2: "",
        zip_code: "",
        city: "",
        country: "Norway",
        country_code: "NO",
        state: "",
        phone_number: "",
        sort_order: 1
    };
    
    const initialValues: PackageInfoForIntegration = {
        _id: "",
            company: "",
            customer: "",
            tracking_number: "",
            pickup_address: initialAddress,
            delivery_address: initialAddress,
            load_types: "",
            load_description: "",
            load_value: 0,
            service: "",
            vehicleCode: "",
            priceCode: "",
            price: 0,
            integration_id: "",
            integration_state: "",
            integration_state_message: "",
            integration_state_date: "",
            state: ""
      };

    const { state } = useLocation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [packageInfo, setPackageInfo] = useState<PackageInfoForIntegration | null>(initialValues);
    const [pickupAddress, setPickupAddress] = useState<PackageAddress | null>(initialValues.pickup_address);
    const [deliveryAddress, setDeliveryAddress] = useState<PackageAddress | null>(initialValues.delivery_address);

    const { tracking_number } = state as CargoMatchState;
    let isError = false;

    
    

    useEffect(() => {
        const getPackage = async () => {
            const packageInfo: PackageInfoForIntegration | null =  await getExtendedPackageInfoForIntegration(tracking_number);
            if(packageInfo !== null){
                setPackageInfo(packageInfo);
                setPickupAddress(packageInfo.pickup_address);
                setDeliveryAddress(packageInfo.delivery_address);

                initialValues.delivery_address = packageInfo.delivery_address || {...initialValues.delivery_address};
                initialValues.pickup_address = packageInfo.pickup_address || {...initialValues.pickup_address};
                handleReset(packageInfo, null);
                setHasError(false);
                setErrorMessage("");

            } else {
                setHasError(true);
                setErrorMessage("Vi trenger mer informasjon om transporten for å sende den til transportør.");
            }
            
        };

        getPackage();

    }, [tracking_number]);

    
    
  const onSubmit = async (packageInfo2: any) => {
    console.log("Submit this? ",packageInfo);
    if(packageInfo !== null) {
      await updateExtendedPackageInfoForIntegration(
        tracking_number,
        {...packageInfo,
            pickup_address: pickupAddress ?? {},
            delivery_address: deliveryAddress ?? {}
        }
      )
        .then(
            (response) => {
                console.log(response);
                setPackageInfo(packageInfo);
                setPickupAddress(packageInfo.pickup_address);
                setDeliveryAddress(packageInfo.delivery_address);

                props.updatePackageIntegrationInfo(packageInfo);
                onClose();
            }
        )
        .catch((error) => {
          setHasError( true ) ;
          setErrorMessage(error.data.detail);
          //console.log(error.data.detail)
          //console.log(hasError);
        });
    }
  };

  // Resets the form
  function handleReset(values: any, _formProps: any) {
    setPackageInfo(values);
    setPickupAddress(values.pickup_address);
    setDeliveryAddress(values.delivery_address);
  }



  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)

  return (
    <>
      <Button onClick={onOpen} style={{'marginTop':'15px'}} ref={finalRef}>{errorMessage!=='' ? 'Legg inn mer informasjon...' : 'Oppdater adresser...'}</Button>

{errorMessage!=="" ? (<div style={{'padding':'15px'}}>
      <Alert
        status={hasError? "error" : "success"}
      >{errorMessage}</Alert></div>):
      (packageInfo !== null && (<div style={{'padding':'15px'}}>
        <SimpleGrid columns={2} columnGap="4">
                <div>
                    <Text variant="p" fontWeight={'bold'}>Pickup adresse:<br></br></Text>
                    <div>{packageInfo.pickup_address.street_address}</div>
                    <div>{pickupAddress?.street_address2 ?? ''}</div>
                    <div>{packageInfo.pickup_address.zip_code} {packageInfo.pickup_address.city}</div>
                    <div></div>
                </div>
                <div>
                    <Text variant="p" fontWeight={'bold'}>Leverings-adresse:<br></br></Text>
                    <div>{packageInfo.delivery_address.street_address}</div>
                    <div>{packageInfo.delivery_address.street_address2}</div>
                    <div>{packageInfo.delivery_address.zip_code} {packageInfo.delivery_address.city}</div>
                    <div></div>
                 </div>
</SimpleGrid>
      </div>))}
    

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size={'xl'}
      >
        <ModalOverlay />
        <ModalContent>
        <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              //validationSchema={validationSchema}
              onReset={handleReset}
              enableReinitialize
            >
              {({ handleSubmit, values, errors, setFieldValue }) => (
                 <>
          <ModalHeader>Utvidet informasjon om sendingen</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>


          
               
                <SimpleGrid columns={2} columnGap="4">
                <div>
                    <Text variant="label">Pickup adresse:</Text>
                    <Input
                        variant="standardTextInput"
                        type="text"
                        placeholder="Gatenavn og nummer"
                        name="pickup_address_street_address"
                        value={pickupAddress?.street_address}
                        onChange={(e) => {
                            setFieldValue("pickup_address.street_address", e.target.value);
                            //use setPackageInfo hook to update pickup_address
                            setPickupAddress(prevState => ({
                                ...prevState,
                                street_address: e.target.value
                            }));
                        
                        }}
                    />
                    <Input
                        variant="standardTextInput"
                        type="text"
                        placeholder="Evt. adresselinje 2"
                        name="pickup_address_street_address2"
                        value={pickupAddress?.street_address2}
                        onChange={(e) => {
                            setFieldValue("pickup_address.street_address2", e.target.value);
                            setPickupAddress(prevState => ({
                                ...prevState,
                                street_address2: e.target.value
                            }));
                        }}
                    />
                    <Input
                        variant="standardTextInput"
                        type="text"
                        placeholder="Postnummer"
                        name="pickup_address_zip_code"
                        value={pickupAddress?.zip_code}
                        onChange={(e) => {
                            setFieldValue("pickup_address.zip_code", e.target.value);
                            setPickupAddress(prevState => ({
                                ...prevState,
                                zip_code: e.target.value
                            }));
                        }
                        }
                    />
                    <Input
                        variant="standardTextInput"
                        type="text"
                        placeholder="Poststed"
                        name="pickup_address_city"
                        value={pickupAddress?.city}
                        onChange={(e) => {
                            setFieldValue("pickup_address.city", e.target.value);
                            setPickupAddress(prevState => ({
                                ...prevState,
                                city: e.target.value
                            }));
                        }
                        }
                    />
                </div>
                <div>
                    <Text variant="label">Leverings-adresse:</Text>
                    <Input
                        variant="standardTextInput"
                        type="text"
                        placeholder="Gatenavn og nummer"
                        name="delivery_address_street_address"
                        value={deliveryAddress?.street_address}
                        onChange={(e) => {
                            setFieldValue("delivery_address.street_address", e.target.value);
                            setDeliveryAddress(prevState => ({
                                ...prevState,
                                street_address: e.target.value
                            }));
                        }
                        }
                    />
                    <Input
                        variant="standardTextInput"
                        type="text"
                        placeholder="Evt. adresselinje 2"
                        name="delivery_address_street_address2"
                        value={deliveryAddress?.street_address2}
                        onChange={(e) => {
                            setFieldValue("delivery_address.street_address2", e.target.value);
                            setDeliveryAddress(prevState => ({
                                ...prevState,
                                street_address2: e.target.value
                            }));
                        }
                        }
                    />
                    <Input
                        variant="standardTextInput"
                        type="text"
                        placeholder="Postnummer"
                        name="delivery_address_zip_code"
                        value={deliveryAddress?.zip_code}
                        onChange={(e) => {
                            setFieldValue("delivery_address.zip_code", e.target.value);
                            setDeliveryAddress(prevState => ({
                                ...prevState,
                                zip_code: e.target.value
                            }));
                        }}
                    />
                    <Input
                        variant="standardTextInput"
                        type="text"
                        placeholder="Poststed"
                        name="delivery_address_city"
                        value={deliveryAddress?.city}
                        onChange={(e) => {
                            setFieldValue("delivery_address.city", e.target.value);
                            setDeliveryAddress(prevState => ({
                                ...prevState,
                                city: e.target.value
                            }));}
                        }
                    />
                 </div>
</SimpleGrid>
               
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} type="submit" onClick={() => onSubmit(values)}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
          </>

)}
            </Formik>
        </ModalContent>
      </Modal>
    </>
  );
}